.v-list--three-line .v-list__tile__sub-title {
  line-height: 16px !important;
}

.v-list__tile__avatar {
  &.red-avatar {
    img {
      background-color: $ipu-red;
    }
  }

  &.red-blue-avatar {
    img {
      background: linear-gradient(to right, #1262a0 0%, #e34a3a 100%);
    }
  }

  &.red-yellow-avatar {
    img {
      background: linear-gradient(to left, #f4c217 20%, #e34a3a 100%);
    }
  }

  &.green-yellow-avatar {
    img {
      background: linear-gradient(to right, #f4c217 0%, #1e9547 100%);
    }
  }

  &.green-avatar {
    img {
      background-color: $ipu-green;
    }
  }

  &.blue-avatar {
    img {
      background-color: $ipu-blue;
    }
  }

  &.green-blue-avatar {
    img {
      background: linear-gradient(to left, #1262a0 0%, #1e9547 100%);
    }
  }

  &.yellow-avatar {
    img {
      background-color: $ipu-yellow;
    }
  }
}

// .v-list {
//   padding: 0;
//   margin-bottom: 1rem;
// }

.v-list__tile__title,
.v-list__tile__content {
  &:hover {
    color: $white !important;
  }
}

#onboarding,
#notification-settings,
#privacy-settings,
#email-notifications {
  .v-list__tile__content {
    height: auto !important;
  }

  .v-list__tile__title {
    height: auto !important;
    white-space: normal !important;
  }

  .v-list__tile__sub-title {
    height: auto !important;
    white-space: normal !important;
    -webkit-line-clamp: inherit;
  }

  .v-list__tile.list__tile--link {
    height: auto;
    padding: 1rem 1rem 1rem 1rem;
  }
}

.list-complete-item {
  padding: 0 2px;
  margin-top: 4px;
  display: inline-block;
  border-radius: 28px !important;
  transition: all 0.3s;
}

.filter-menu {
  overflow: hidden;

  form {
    width: 100%;
  }

  .v-list__tile__title:hover {
    color: $white !important;
  }
}

.terms-text {
  p {
    line-height: 1.5rem !important;
  }

  &.terms-text-customer {
    ol {
      list-style-type: none;
      counter-reset: item;
      margin: 0;
      padding: 0;
    }

    ol > li {
      display: table;
      counter-increment: item;
      margin-bottom: 0.6em;
    }

    ol > li::before {
      content: counters(item, ".") ". ";
      display: table-cell;
      padding-right: 0.6em;
    }

    li ol > li {
      margin: 0;
    }

    li ol > li::before {
      content: counters(item, ".") " ";
    }
  }
}

.v-autocomplete__content {
  .v-list__tile__mask {
    color: $white !important;
    background: none !important;
  }

  .v-list__tile__content {
    color: hsla(0, 0%, 100%, 0.5) !important;
  }
}

.settings-mobile-menu {
  padding: 0 !important;
  margin: -15px;
  border-radius: 0 !important;

  .v-list__tile--active {
    color: $white !important;
  }
}
