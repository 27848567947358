.button-container {
  text-align: right;
  // padding: .8rem 0 0 0;
  .v-input {
    display: inline-block;
    width: 9rem;
  }
}

.v-btn {
  //text-transform: capitalize;
}

.visibility-btn {
  z-index: 1;
}

.v-btn--raised {
  cursor: pointer;
}

.cta-btn {
  background: $success !important;
}

.error-btn {
  background: $error !important;
  color: $white;
}

.v-btn--floating {
  &.v-btn--small {
    i {
      line-height: 40px;
    }
  }
}

#public-star {
  cursor: default !important;
}

.fab-30-px {
  .v-btn {
    height: 30px !important;
    width: 30px !important;
  }

  .v-btn--floating.v-btn--small i {
    line-height: 30px !important;
  }
}

form {
  .v-btn {
    margin-right: 0;
  }
}

.v-speed-dial--bottom:not(.v-speed-dial--absolute) {
  display: inline-block;
  bottom: 5px;
  right: 11px;
  z-index: 2;

  .v-btn {
    font-size: 11px;
    margin-right: 0 !important;

    i {
      line-height: 40px;
    }
  }

  .v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat) {
    background-color: hsla(0, 0%, 33%, 0.92) !important;
  }
}

.xl-btn {
  height: 56px;
}

.application .theme--dark.v-btn-toggle,
.theme--dark .v-btn-toggle {
  background: $item-background !important;
}

.fab-scaling-icon-hover {
  height: 100%;
  margin: 0 !important;
  width: 100%;

  &:hover {
    i {
      transform: scale(1.2, 1.2);
    }
  }

  i {
    line-height: 33px !important;
    font-size: 22px;
  }
}

.show-more-button {
  margin-bottom: -0.5rem;
}

.add-btn {
  margin: 0;
  width: 45px;
  height: 45px;
}

.add-btn-center {
  text-align: center;
  margin-top: 1.2rem;

  .v-tooltip {
    cursor: pointer;
  }
}

#public-profile-link {
  margin: 1rem 1rem 0.5rem 0;
  display: block;
}

.account-info-card-btn {
  position: relative;
  display: inline-block;

  .check-icon {
    position: absolute;
    bottom: 0.1rem;
    right: 0.2rem;
  }
}

.add {
  &:hover {
    color: $green;
  }
}

.relative-flex {
  position: relative;

  .action-buttons-profile {
    position: absolute;
    top: 0;
    right: 10px;
  }
}

.show-more-div {
  background: lighten($item-background, 5%);
}

.max-width-btn {
  width: 100%;
  margin: 0 !important;
}

.right-aligned-button {
  margin-left: auto;
}

.eniro-append-btn {
  margin-top: -0.4rem;
}

.add-type-button {
  &:hover {
    .v-icon {
      color: $accent-color;
      transform: scale(1.1, 1.1);
    }
  }
}
