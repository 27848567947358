.uploaded-text {
  @media screen and (max-width: 700px) {
    h5 {
      width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.download-document {
  display: flex;
  align-items: flex-start;
  padding: 0.5rem 1rem;

  h5 {
    margin-bottom: 0 !important;
    line-height: 1rem;
  }

  .text {
    margin: 0 0.5rem;
  }
}

.label-button {
  width: 10rem;
  font-weight: 400;

  @media screen and (max-width: 400px) {
    width: auto;
  }
}

.buttons-halfer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  margin: 1rem 0 0 0;
  justify-content: space-around;

  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
}

.download-document-profile {
  .text {
    cursor: default !important;

    &:hover {
      text-decoration: none !important;
    }
  }
}
