.StripeElement {
  background-color: lighten($item-background, 5%);
  // background-color: $darker-item;
  height: 57px;
  padding: 20px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 darken($darker-item, 5%);
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 $darker-item;
}

.StripeElement--invalid {
  border-color: #fa755a;
  box-shadow: 0 1px 3px 0 #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: $darker-item !important;
}

.StripeElement .Icon-fill {
  fill: $white !important;
}

.credit-card-container {
  padding: 1rem 0.5rem;
  border: 1px rgba(255, 255, 255, 0.3);
  border-style: solid none none;

  @media screen and (min-width: 600px) {
    padding: 1.5rem;
  }
}

.stripe-logo {
  width: 8rem;
}

.card-type {
  width: 2rem;
}

.flex-card {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
