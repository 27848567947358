@import "_variables";
// GENERAL CARD
.top-card {
  background: linear-gradient(to right, $primary-color 0%, $fourth-color 100%);
  border-radius: 24px;
  padding-top: 0.2rem;

  &.top-card-danger {
    background: linear-gradient(
      to right,
      $accent-color 0%,
      $second-accent 100%
    );
  }
}

.v-card__title {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  hyphens: auto;
  max-width: 100%;
  flex: 1;

  h4 {
    margin-bottom: 0;
  }

  .v-checkbox {
    min-width: 9rem;
  }

  .v-input--text-field {
    min-width: 10rem;
  }
}

.table-card {
  margin: 1rem auto;
}

.card-title {
  margin: 0;
  text-align: left;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  align-items: center;

  .v-icon {
    margin-right: 0.5rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 1.2rem;

    .v-icon {
      font-size: 1.2rem;
    }
  }
}

.profile-pages {
  .card-title {
    @media screen and (max-width: 500px) {
      font-size: 1rem;

      .v-icon {
        font-size: 1rem;
      }
    }
  }
}

.card-table {
  h5 {
    margin-bottom: 1rem;
  }

  padding: 0;
}

.v-card__title--primary {
  padding: 0.5rem 1rem;
}

.card-content {
  max-width: 100%;
  margin-right: 1.8rem;
}

.dashboard-container,
.team-container {
  .v-card {
    overflow: hidden;
    position: relative;
    margin: 1rem 0;

    @media screen and (min-width: 500px) {
      margin: 0;
    }

    .wave {
      position: absolute;
      bottom: 0;
      width: 100%;
      opacity: 0.8;
    }
  }
}

.ipu-purchase-cards {
  .v-card {
    display: flex;
    flex-direction: column;
    position: relative;

    .wave-ipu {
      position: absolute;
      top: 0;
      width: 100%;
    }

    .v-card__title {
      justify-content: center;
      padding: 0.8rem 1rem;
      z-index: 1;
      background-color: $item-background;
    }

    .icon-container {
      text-align: center;

      .v-icon {
        font-size: 4rem;
        margin-top: 0.3rem;
      }
    }

    h3,
    h4 {
      text-align: center;
    }

    .v-card__text {
      z-index: 1;
      height: 100%;
      display: flex;
      flex-direction: column;

      h3 {
        margin-top: auto;
        margin-bottom: 0;

        small {
          color: $white;
        }
      }
    }

    .v-card__actions {
      text-align: center;
      margin-top: auto;
      margin-bottom: 0.5rem;

      a {
        &:hover {
          color: $white !important;
        }

        text-decoration: none !important;
      }
    }

    height: 100% !important;
    box-shadow: 0 0 22px 3px rgba(0, 0, 0, 0.14),
      0 0 22px 3px rgba(0, 0, 0, 0.14), 0 0 22px 3px rgba(0, 0, 0, 0.14);
  }
}

.ipu-cards {
  margin-bottom: 1rem;
  background-color: rgba($black, 0.1) !important;

  .v-card {
    background-color: rgba($black, 0.1) !important;
  }

  .ipu-purchase-cards {
    .v-card {
      .v-card__text {
        display: block;
        padding-bottom: 8px;
      }
    }
  }
}

.settings {
  .top-card {
    margin-bottom: 1rem;

    @media screen and (max-width: 500px) {
      width: 100%;
      min-width: 100%;
    }

    &.comp {
      max-width: 700px;
      margin: 2rem auto 1rem auto !important;
    }
  }
}

// subscription page
.module-card {
  .caption {
    height: 17px;
  }
}

.t-search-card {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}

.t-search-card-percent {
  position: absolute;
  top: -1px;
  left: 4px;
  font-size: 12px;
  font-weight: 600;
  z-index: 1;
}

.t-search-card-progress {
  border-radius: 2px 2px 0 0 !important;
}

.card-header {
  padding-left: 2em;
  text-align: left;

  @media screen and (max-width: 500px) {
    padding: 1rem 0;
  }

  .card-title {
    margin: 0.5em 0;
  }
}

.fill-height-card {
  height: 100% !important;
}

.resume-titles {
  display: flex;
  margin-bottom: 0.4rem;
  align-items: center;

  .v-tooltip {
    margin-left: auto;
  }
}

.resume-cards {
  .reference-content {
    display: flex;
    padding: 0 0.5rem 0;

    &:first-of-type {
      padding-top: 0.5rem;
    }

    .v-tooltip {
      margin-left: auto;
    }
  }

  .skill-boxes .v-card__actions {
    padding: 0 !important;
  }

  .add-reference {
    cursor: pointer;
    padding: 0.5rem !important;
  }
}

.dark-card-bg {
  background-color: $darker-item;
}

.pricing-card {
  display: flex;
  flex-direction: column;

  .pricing-bullets {
    height: 100%;
    display: flex;
    flex-direction: column;

    .pricing-button-div {
      display: flex;
      margin-top: auto;
    }
  }
}

.onboarding-flex {
  @media screen and (max-width: 600px) {
    margin: -9.5px 0 -9.5px -9.5px;
  }
}

.search-module-card {
  &:hover {
    color: $white !important;
    background: lighten($item-background, 5%) !important;
  }

  &.info-card {
    height: 100%;
  }
}

.card-item-background {
  background-color: $item-background !important;
}
