@import "_variables";
// landing

.v-navigation-drawer > .v-list .v-list__tile--active > :first-child .v-icon {
  color: $white;
}

.activeTab {
  .v-icon {
    color: $accent-color !important;
  }
}

#landing-toolbar {
  background-color: $navbar-color;

  .v-navigation-drawer {
    background-color: $navbar-color;
  }
}

.v-toolbar--absolute {
  z-index: 999;
}

.v-toolbar__items {
  .v-btn {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 300;
  }
}

// build number CSS

.rainbow {
  color: #f35626;
  background-image: -webkit-linear-gradient(92deg, #f35626, #feab3a);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: hue 10s infinite linear;
  animation: hue 10s infinite linear;
}

@-webkit-keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }

  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}

@keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }

  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}
