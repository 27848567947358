.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 90%;
}

.resume-dialog {
  max-width: 1200px !important;
}

.v-dialog {
  position: relative;
  z-index: 30000;
  max-width: 580px;

  @media screen and (max-width: 600px) {
    padding: 0;
    max-width: 100%;
  }

  @media screen and (max-width: 430px) {
    margin: 0;
  }

  .close-btn {
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: #b9b9b9;
    cursor: pointer;
  }

  .inner-dialog {
    background: $item-background;
    padding: 2.5rem 1rem 1rem 1rem;

    h1,
    h5,
    h2,
    h3 {
      text-align: center;
    }

    h1 {
      margin-bottom: 1rem;

      @media screen and (max-width: 450px) {
        font-size: 2.5rem;
      }
    }

    h5 {
      margin-bottom: 1rem;
    }

    h2,
    h3 {
      .v-icon {
        font-size: 2.5rem;
        margin: 0 0.5rem 0 0;
      }
    }
  }
}

.v-dialog__content__active {
  z-index: 30000 !important;
}

.v-dialog:not(.v-dialog--fullscreen) {
  @media screen and (max-width: 500px) {
    max-height: 95% !important;
  }
}

.larger-dialog-tablet-up {
  .v-dialog {
    @media screen and (min-width: 500px) {
      min-width: 450px;
    }
  }
}

.mobile-overflow-x {
  overflow-x: auto;
}

.v-small-dialog__content,
.v-small-dialog__actions {
  background: darken($item-background, 5%) !important;
}

.v-small-dialog .v-menu__activator {
  min-width: 5rem;
}

.v-small-dialog__content {
  background: lighten($item-background, 1%) !important;
  padding: 0 !important;

  form {
    width: 100%;
  }

  .v-input--selection-controls__input {
    margin-right: 0;
  }

  .v-list__tile__action {
    min-width: 1.5rem;
  }
}

.search-edit-dialog-input {
  @media screen and (min-width: 600px) {
    min-width: 315px;
  }
}

.larger-dialog-tablet-up {
  .v-icon {
    margin-bottom: 0;
  }
}

// For graphical profile page
.dialog-page {
  .v-dialog {
    max-width: 480px !important;
    box-shadow:
      0 11px 15px -7px rgba(0, 0, 0, 0.2),
      0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
}
