.pulse {
  color: #c23b22 !important;
  font-size: 20px;
  animation: pound 0.5s infinite alternate;
  -webkit-animation: pound 0.5s infinite alternate;
}

.love {
  font-size: 16px;
}

.footer-card {
  background-color: $darker-item !important;
  width: 100%;
}

@-webkit-keyframes pound {
  to {
    transform: scale(1.1);
  }
}

@keyframes pound {
  to {
    transform: scale(1.1);
  }
}
