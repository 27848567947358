.top-nav-menu {
  a:hover {
    color: $white !important;
  }
}

.v-menu__content--autocomplete {
  &.v-menu__content {
    .v-list {
      transition: all 0.3s;

      &:hover {
        background-color: darken($darker-item, 5%) !important;
      }
    }
  }
}

.request-edit-box {
  display: flex;
  align-items: center;

  .remove-icon {
    margin-left: 1rem;
    cursor: pointer;
  }

  .v-input-group__prepend-icon {
    min-width: 30px !important;
  }

  .v-menu {
    max-width: 10rem;

    label {
      font-size: 14px;
      left: 33px;
    }
  }

  .v-menu:not(:first-of-type) {
    margin-left: 0.5rem;
  }

  .v-input--text-field.v-input--prepend-icon .v-input-group__details {
    max-width: calc(100% - 30px) !important;
  }
}

.v-menu__content {
  .v-list {
    margin-bottom: 0 !important;
  }
}
