#company-details-form {
  display: flex !important;
  flex-wrap: wrap;
  width: 100% !important;
}

.view-companies-table {
  @media screen and (max-width: 1300px) {
    flex-direction: row !important;

    @media screen and (max-width: 700px) {
      flex-direction: column !important;
    }
  }

  .view-companies-div {
    display: flex;
    margin-left: auto;

    @media screen and (max-width: 700px) {
      flex-direction: column;
      margin-left: 0;
      width: 100%;

      .v-input--text-field {
        padding-right: 0 !important;
      }
    }

    .v-input {
      width: 100%;
    }
  }
}

.add-disc {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span {
    width: 8rem;

    @media screen and (max-width: 500px) {
      flex-basis: 100%;
      margin-top: 1rem;
    }
  }

  .v-input {
    max-width: 7rem;
    margin-right: 1rem;
  }
}

.wheel-pos {
  @media screen and (min-width: 700px) {
    display: flex;
    flex-wrap: wrap;

    .ipu-main {
      flex-basis: 100%;
    }
  }

  .positions-wheel {
    margin-left: 1rem;
    width: auto;

    @media screen and (min-width: 700px) {
      flex-basis: 45%;
    }
  }
}

.create-role-dialog {
  margin: 0.7rem 0;

  .v-input-group__details {
    min-height: 0;
    padding-top: 0;
  }

  hr {
    border-color: hsla(0, 0%, 100%, 0.22);
    margin-top: 1rem;
  }
}

.input-no-padding {
  &.title-container-card {
    .v-input {
      padding-top: 0;
    }
  }
}

.form-group {
  padding: 0.5em 0;

  #number-label {
    padding: 0 0.3em;
  }
}

.v-input label {
  overflow: initial;
}

input[type="file"] {
  display: none;
}

.v-input label {
  overflow: hidden;
}

.v-select__slot {
  .v-input__icon--append {
    .v-icon {
      font-size: 32px;
    }
  }
}

.auth-form {
  width: 85%;
  margin: auto;
}

.auth-form-action {
  margin: auto;
  margin-top: 3rem !important;
}

.g-recaptcha {
  margin-left: -1rem;
  margin-bottom: 1.5rem;
}

// color circle for personality
.color-circle {
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 0.5rem;
  margin-right: 0.4rem !important;
  background-color: black;

  &.menu-circle {
    margin-right: 0.8rem !important;
  }

  &.Relater {
    background: linear-gradient(to left, #f4c217 0%, #1e9547 100%);
  }

  &.Coordinator {
    background: linear-gradient(to left, #1262a0 0%, #1e9547 100%);
  }

  &.Persuader {
    background: linear-gradient(to left, #f4c217 20%, #e34a3a 100%);
  }

  &.Conductor {
    background-color: $ipu-red;
  }

  &.Promoter {
    background-color: $ipu-yellow;
  }

  &.Supporter {
    background-color: $ipu-green;
  }

  &.Analyser {
    background-color: $ipu-blue;
  }

  &.Implementor {
    background: linear-gradient(to right, #1262a0 0%, #e34a3a 100%);
  }

  -moz-box-shadow: -1px 1px 3px #000;
  -webkit-box-shadow: -1px 1px 3px #000;
  -o-box-shadow: -1px 1px 3px #000;
  box-shadow: -1px 1px 3px #000;
}

.page-title-select {
  margin: 0;
  padding: 15px 0 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;

  &.team-btn {
    padding-top: 0 !important;
  }

  @media screen and (min-width: 430px) {
    flex-direction: row;
    width: auto;
    align-self: flex-end;
    margin-left: auto;

    .v-input {
      min-width: 14rem;
      max-width: 14rem;
    }
  }

  &.timrep-select {
    margin: 0 0 0 auto;
  }

  @media screen and (min-width: 1000px) {
    margin: 0;
    margin-left: auto;
  }
}

.team-form {
  margin-top: 2rem;
  width: 100% !important;
}

#saved-search {
  &.v-input {
    max-width: 13rem !important;
  }
}

#profile {
  .v-input {
    white-space: nowrap;
  }
}

.no-result-div {
  background: $darker-item;
  padding: 0.4rem;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}

.suggest-a-skill {
  .current-skill {
    max-width: 26.5rem;
    margin: auto;
  }
}

.survey-checkbox-field {
  .v-input {
    flex: unset;
  }
}

.event-select {
  max-width: 13rem;
}
