@import "_variables";
@import "_animations";
@import "_alerts";
@import "_badge";
@import "_breadcrumbs";
@import "_button";
@import "_checkbox";
@import "_chip";
@import "_dialog";
@import "_disc-page";
@import "_expansion-panel";
@import "_file-upload";
@import "_footer";
@import "_form";
@import "_graphs";
@import "_icon";
@import "_ie";
@import "_image";
@import "_layout";
@import "_list";
@import "_loader";
@import "_menu";
@import "_navbar";
@import "_picker";
@import "_stepper";
@import "_stripe";
@import "_switch";
@import "_table";
@import "_text";
@import "_card";
@import "_app";

html {
  max-width: 100%;
  width: auto !important;
  height: auto;
  overflow-y: auto;
}

.dark--text {
  color: #0a1b25 !important;
}

body {
  transition: all 0.3s;
  max-width: 100%;
  width: auto !important;
}

#app {
  background: transparent;
}

$font-family: 'Open Sans', sans serif;

.v-application {
  [class*='text-'] {
    font-family: $font-family, sans-serif !important;
  }

  font-family: $font-family, sans-serif !important;
}

//animations
.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter {
  transform: translateX(-100%);
}

.slide-fade-leave-to {
  transform: translateX(0%);
}

.fade-enter-active {
  transition: all 0.5s ease-in-out;
}

.fade-leave-active {
  transition: all 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.fade-leave-to {
  opacity: 0;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: lighten($item-background, 5%);
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: darken($item-background, 10%);
}

::-webkit-scrollbar-thumb {
  background-color: lighten($item-background, 5%);
}

.overlay--active::before {
  opacity: 0.65 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

footer {
  background-color: $footer-darker;
  color: $paragraph-color;
}

.light-background {
  background-color: #e2e2e2 !important;
  background-repeat: initial;
}