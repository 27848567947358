.closed {
  margin-left: -0.8rem;
  z-index: 10;
  padding: 0 !important;
  max-width: 0 !important;
  min-width: 0 !important;
  max-height: 680px;
  flex-basis: 0% !important;
  transition: all 0.5s;
  overflow: hidden;
}

.input-group-radio-purchase {
  display: block;
}

.expansion-purchase-type {
  padding: 0 5px;

  @media screen and (min-width: 600px) {
    padding: 1rem;
  }

  .v-input__control {
    width: 100% !important;
  }
}

.flex-header {
  display: flex;
  width: 100%;
  padding: 18px 29px;
}
