.top-progress {
  margin: 0;
}

.expanded-row {
  .v-progress-linear {
    max-width: 10rem;
  }

  .v-progress-linear__bar,
  .v-progress-linear__bar__determinate,
  .v-progress-linear__background {
    border-radius: 0.5rem;
  }
}

.search-progress {
  p {
    margin-bottom: 0;
  }

  .v-progress-linear__bar,
  .v-progress-linear__bar__determinate,
  .v-progress-linear__background {
    border-radius: 0.5rem;
  }
}

.matched-bar {
  display: none;

  @media screen and (min-width: 980px) {
    display: inline-block;
  }
}

.progress-top {
  display: inline-block;
  display: inline-flex;
  align-items: center;
  margin: 1rem;

  .v-progress-circular {
    margin-left: 1rem;

    @media screen and (max-width: 400px) {
      margin-top: 0.2rem;
      margin-left: 0.7rem;
    }

    &.teal--text {
      color: $accent-color !important;
    }

    font-size: 0.8rem;
  }
}

.profile-completion {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1905px) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;

    .wraps {
      flex-basis: 33%;
      padding: 0 1rem;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;

    .wraps {
      flex-basis: 90%;
      padding: 0 1rem;
    }
  }
}

.spin-loader {
  float: right;
}
