@import "_variables";

// IPU page CSS
.icon-circle {
  margin: auto;
  width: 6rem;
  height: 6rem;
  margin-bottom: 1rem;
  background: $primary-color;
  border-radius: 50%;
  transition: 0.5s all;
}

.slide-toggle-enter-active,
.slide-toggle-leave-active {
  transition: height 0.5s;
}

.slide-toggle-enter-active {
  height: 100rem;

  @media screen and (min-width: 1000px) {
    height: 35rem;
  }
}

.slide-toggle-enter,
.slide-toggle-leave-active {
  height: 0;
}

.slide-toggle-leave {
  height: 100rem;

  @media screen and (min-width: 1000px) {
    height: 35rem;
  }
}
