.card-title-checkbox {
  .v-checkbox {
    label {
      font-size: 13px !important;
    }

    .v-icon--selection-control {
      height: 24px !important;
    }

    .v-input-group__details {
      min-height: 0;
      padding: 0;
    }
  }
}

.width-2rem-checkbox {
  .v-checkbox {
    width: 2rem;
  }
}

// settings checkbox and text div
.toggle-box {
  @media screen and (max-width: 600px) {
    height: inherit;
    padding: 10px;
  }
}
