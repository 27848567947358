.cursor-pointer {
  cursor: pointer;

  .v-chip__content {
    cursor: pointer !important;
  }
}

.normal-cursor {
  cursor: default;
}

.cursor-move {
  cursor: move;
  cursor: grab;
}

.fixed-height-width-chip {
  height: 1.5rem;
  width: 7rem !important;
}

.primary-chip,
.success-chip,
.purple-chip {
  background-color: $success !important;
  color: $white;
}

.warning-chip,
.pending-chip {
  background-color: $warning !important;
  color: $white;
}

.error-chip {
  background-color: $error !important;
  color: $white;
}

.dark-chip,
.neutral-chip {
  background: $neutral !important;
  color: $white !important;
}

.blue-chip {
  background-color: $primary-color !important;
  color: $white;
}

.margin-chip {
  margin: 0.2rem;
}

.gradient-chip {
  transition: all 0.5s ease;
  background: linear-gradient(to right, #099 0%, #09c 100%) !important;
}

.t-small-avatar-chip {
  .v-avatar {
    height: 24px !important;
    width: 24px !important;
  }
}

.avatar-chip {
  transition: all 0.3s;
  background-color: darken(#099, 4%);
  margin: 0 0.5rem 0 0 !important;

  .disabled {
    color: hsla(0, 0%, 100%, 0.5);
    background-color: rgba(228, 228, 228, 0.6) !important;
    cursor: default;

    &:hover {
      i {
        transform: none;
      }
    }
  }
}

.fav-star {
  position: absolute;
  top: -13px;
  left: -8px;
  z-index: 1;

  &:hover,
  &:focus {
    position: absolute !important;
  }
}

.skills-tabs-team {
  min-height: 455px !important;

  .v-chip--active::after,
  .v-chip--selected::after,
  .v-chip:focus:not(.v-chip--disabled)::after,
  .v-chip:focus:not(.v-chip--disabled) {
    transition: 0.3s all;
    background: $second-accent !important;
    opacity: 1;
  }
}

.search-card {
  .v-chip {
    margin: 0.3rem 0;
    border-radius: 28px !important;
  }
}

.search-terms {
  .v-chip {
    margin: 0.3rem 0.1rem;
    max-width: 100%;

    .v-chip__content {
      cursor: pointer;
      max-width: 100%;
      word-break: break-word;
      overflow-wrap: break-word;
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
      white-space: normal;
      height: auto;
      line-height: 1.2rem;
    }
  }
}

// Graphical profile page
.nav-drawer {
  .v-chip--small {
    height: 16px;
    font-size: 10px;
  }
}

.chip-page {
  .v-chip {
    margin: 0 0.5rem;
  }
}

.un-selected-skill {
  opacity: 0.3;
}

.sortable-chosen,
.sortable-ghost {
  border-radius: 28px !important;
}

.router-link-chip {
  a {
    text-decoration: none;

    &:hover {
      color: $white !important;
    }
  }
}
