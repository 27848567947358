// main app
.relative-postitioned-element {
  position: relative;
}

.absolute-top-right {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.absolute-bottom-right {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}

#Smallchat {
  iframe {
    z-index: 4 !important;
  }
}

.non-active-background {
  opacity: 0.4;
}

.closedContainer {
  padding: 10px;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.z-index-1 {
  z-index: 1 !important;
}

.right-position-0 {
  right: 0;
}

.margin-all-0 {
  margin: 0 !important;
}

.margin-1-top {
  margin-top: 1rem;
}

.no-margin-right {
  margin-right: 0 !important;
}

.margin-xsmall-y-no-x {
  margin: 0.2rem 0;
}

.margin-top-1-mobile {
  @media screen and (max-width: 660px) {
    margin-top: 1rem !important;
  }
}

.margin-left-auto-mobile {
  @media screen and (max-width: 600px) {
    margin-left: auto;
  }
}

.left-auto {
  margin-left: auto !important;
}

.padding-all-0 {
  padding: 0 !important;
}

.flex-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  flex-direction: row;
}

.flex-row-align-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.height-600 {
  height: 600px;
}

.max-width-400-auto {
  max-width: 400px;
  margin: auto;
}

// color classes
.yellow--text,
.primary--text,
.accent--text {
  color: $accent-color !important;
}

.v-input--error,
.error--text {
  label {
    color: #f8b5ef !important;
  }

  .v-input-group__details {
    color: #f8b5ef !important;
  }

  i {
    color: #f8b5ef !important;
  }

  color: #f8b5ef !important;
}

.v-input.v-input--text-field:not(.v-input--single-line):not(.v-input--error).v-input--focused .v-input-group__input {
  border-color: $accent-color !important;
}

.application--dark .v-picker--time .v-picker--time__clock,
.application--dark .v-picker .v-picker__title,
.application .theme--dark.v-picker .v-picker__title,
.application .theme--dark.v-picker .v-picker__title,
.theme--dark .v-picker .v-picker__title,
.application .theme--dark.v-picker--time .v-picker--time__clock,
.theme--dark .v-picker--time .v-picker--time__clock {
  background: lighten($item-background, 5%) !important;
}

.application--dark .v-picker .v-picker__body,
.application .theme--dark.v-picker .v-picker__body {
  background: darken($item-background, 5%);
}

.dashboard-container {
  .application--dark .v-picker--time .v-picker--time__clock,
  .application--dark .v-picker .v-picker__title,
  .application .theme--dark.v-picker .v-picker__title,
  .application .theme--dark.v-picker .v-picker__title,
  .theme--dark .v-picker .v-picker__title,
  .application .theme--dark.v-picker--time .v-picker--time__clock,
  .theme--dark .v-picker--time .v-picker--time__clock {
    background-color: $primary-color !important;
  }
}

.accent {
  background: $primary-color !important;
  border-color: $primary-color !important;
}

.light-grey-background {
  background-color: #909ca4;
}

.v-pagination__item,
.v-pagination__navigation {
  background: $darker-item !important;
}

.t-dark-hr {
  color: rgb(37, 91, 118);
  border-color: rgba(37, 91, 118, 0.2);
}

.t-darker-body {
  background: #204c63 !important;
}

.second-accent-background {
  background-color: $second-accent;
}

.accent-background {
  background-color: $accent-color;
}

.green-accent-background {
  background-color: #81c784;
}

.success-background {
  background-color: $success;
}

.primary-background {
  background-color: $primary-color;
}

.secondary-background {
  background-color: $secondary-color;
}

.grey-background {
  background-color: #4f85a0;
}

.darker-primary-background {
  background-color: rgba($primary-color, 0.8) !important;
}

.blue-label {
  background: #6774e3 !important;
}

.dark-red-chip {
  background: #5a2313 !important;
}

// BankID CSS

.bankid-container {
  border-radius: 50%;
  background-color: #e9e9e9;
  height: 100px;

  .bankid-logo {
    padding: 14px;
    height: 100px;
    width: 100px;
  }
}

.bankID-list {
  .theme--dark.v-list {
    background: $darker-item !important;
  }

  .v-list__tile {
    @media screen and (max-width: 400px) {
      padding: 0 5px !important;
    }
  }
}

#bankID-dialog-connect-customer,
#bankID-dialog-sign-customer,
#bankID-dialog {
  .v-stepper__content {
    padding: 0 !important;
  }
}

// Graphical profile css
.pers-test {
  margin-bottom: 7rem;

  ul {
    list-style-type: initial;
    margin-left: 2rem;
  }

  table {
    td {
      color: $white;
    }
  }

  .wheel-container {
    text-align: center;
    margin: 4rem 0 2rem;
  }

  .disc-wheel {
    max-width: 400px;
  }
}
