.contract-breads {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.team-breads {
    align-items: baseline;
  }

  h4 {
    margin-bottom: 0;
    padding-top: 18px;

    @media screen and (min-width: 430px) {
      padding-top: 10px !important;
    }
  }
}

.breadcrumbs-text {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 0;
  padding-top: 10px !important;
  cursor: pointer;
}

.t-breadcrumbs {
  &.v-breadcrumbs li {
    font-size: 20px;
    font-weight: bold;
  }

  &.v-breadcrumbs li:last-child {
    font-size: 20px;
    font-weight: 300;
  }
}

.teal--text {
  color: $secondary-color !important;
}
