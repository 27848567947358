#no-data-text {
  transition: all 1s;
  display: none;
  text-align: center;
  height: 25rem;
  line-height: 10rem;
}

//  pie chart
.ipu-wheel {
  padding-left: 0.5rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  flex-direction: column;

  &.small-ipu-wheel {
    display: inline-block !important;
  }

  @media screen and (min-width: 700px) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  p {
    margin-bottom: 7px;
  }

  .types {
    margin-left: 1rem;
    margin-top: 1rem;

    @media screen and (min-width: 700px) {
      flex-basis: 63%;
      margin-top: 0;
    }
  }

  .v-radio {
    &.v-input {
      label {
        font-size: 14px;
      }
    }
  }
}

#profile {
  .ipu-wheel {
    padding-left: 0;
  }
}

.teams {
  .ipu-wheel {
    width: 360px;
    margin: auto;
    padding-left: 0;
    padding: 1rem 0;
  }
}

.pieContainer {
  transition: all 0.5s;
  height: 180px;
  width: 180px;

  &.small-pie {
    height: 80px;
    width: 80px;
    opacity: 1;
  }

  &.search-pie {
    height: 120px;
    width: 120px;
    opacity: 1;
  }

  &.team-pie {
    height: 360px;
    width: 360px;
    opacity: 1;
  }
}

.pieBackground {
  background-color: grey;
  position: absolute;
  width: 180px;
  height: 180px;
  -moz-border-radius: 90px;
  -webkit-border-radius: 90px;
  -o-border-radius: 90px;
  border-radius: 90px;
  -moz-box-shadow: -1px 1px 3px #000;
  -webkit-box-shadow: -1px 1px 3px #000;
  -o-box-shadow: -1px 1px 3px #000;
  box-shadow: -1px 1px 3px #000;

  &.small-pie-background {
    width: 80px;
    height: 80px;
    -moz-border-radius: 40px;
    -webkit-border-radius: 40px;
    -o-border-radius: 40px;
    border-radius: 40px;
  }

  &.search-pie-background {
    width: 120px;
    height: 120px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
  }

  &.team-pie-background {
    width: 360px;
    height: 360px;
    -moz-border-radius: 180px;
    -webkit-border-radius: 180px;
    -o-border-radius: 180px;
    border-radius: 180px;
  }
}

.pie {
  position: absolute;
  width: 180px;
  height: 180px;
  -moz-border-radius: 90px;
  -webkit-border-radius: 90px;
  -o-border-radius: 90px;
  border-radius: 90px;
  clip: rect(0, 90px, 180px, 0);

  &.small-pie {
    width: 80px;
    height: 80px;
    -moz-border-radius: 40px;
    -webkit-border-radius: 40px;
    -o-border-radius: 40px;
    border-radius: 40px;
    clip: rect(0, 40px, 80px, 0);
  }

  &.search-pie {
    width: 120px;
    height: 120px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
    clip: rect(0, 60px, 120px, 0);
  }

  &.team-pie {
    width: 360px;
    height: 360px;
    -moz-border-radius: 180px;
    -webkit-border-radius: 180px;
    -o-border-radius: 180px;
    border-radius: 180px;
    clip: rect(0, 180px, 360px, 0);
    pointer-events: none;
  }
}

.hold {
  position: absolute;
  width: 180px;
  height: 180px;
  -moz-border-radius: 90px;
  -webkit-border-radius: 90px;
  -o-border-radius: 90px;
  border-radius: 90px;
  clip: rect(0, 180px, 180px, 90px);

  &.small-hold {
    width: 80px;
    height: 80px;
    -moz-border-radius: 40px;
    -webkit-border-radius: 40px;
    -o-border-radius: 40px;
    border-radius: 40px;
    clip: rect(0, 80px, 80px, 40px);
  }

  &.search-hold {
    width: 120px;
    height: 120px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
    clip: rect(0, 120px, 120px, 60px);
  }

  &.team-hold {
    width: 360px;
    height: 360px;
    -moz-border-radius: 180px;
    -webkit-border-radius: 180px;
    -o-border-radius: 180px;
    border-radius: 180px;
    clip: rect(0, 360px, 360px, 180px);
    pointer-events: none;
  }
}

#pieSliceBlue {
  -webkit-transform: rotate(292.5deg);
  -moz-transform: rotate(292.5deg);
  -o-transform: rotate(292.5deg);
  transform: rotate(292.5deg);
}

#pieSliceBlue .pie {
  background-color: $ipu-blue;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#pieSliceGreenBlue {
  -webkit-transform: rotate(247.5deg);
  -moz-transform: rotate(247.5deg);
  -o-transform: rotate(247.5deg);
  transform: rotate(247.5deg);
  backface-visibility: hidden;
}

.flex-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
}

#pieSliceGreenBlue .pie {
  background: linear-gradient(to left, #1262a0 0%, #1e9547 100%);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#pieSliceGreenYellow {
  -webkit-transform: rotate(157.5deg);
  -moz-transform: rotate(157.5deg);
  -o-transform: rotate(157.5deg);
  transform: rotate(157.5deg);
}

#pieSliceGreenYellow .pie {
  background: linear-gradient(to right, #f4c217 0%, #1e9547 100%);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#pieSliceGreen {
  -webkit-transform: rotate(202.5deg);
  -moz-transform: rotate(202.5deg);
  -o-transform: rotate(202.5deg);
  transform: rotate(202.5deg);
}

#pieSliceGreen .pie {
  background-color: $ipu-green;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#pieSliceRed {
  -webkit-transform: rotate(22.5deg);
  -moz-transform: rotate(22.5deg);
  -o-transform: rotate(22.5deg);
  transform: rotate(22.5deg);
}

#pieSliceRed .pie {
  background-color: $ipu-red;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#pieSliceRedBlue {
  -webkit-transform: rotate(337.5deg);
  -moz-transform: rotate(337.5deg);
  -o-transform: rotate(337.5deg);
  transform: rotate(337.5deg);
}

#pieSliceRedBlue .pie {
  background: linear-gradient(to right, #1262a0 0%, #e34a3a 100%);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#pieSliceyellowRed {
  -webkit-transform: rotate(67.5deg);
  -moz-transform: rotate(67.5deg);
  -o-transform: rotate(67.5deg);
  transform: rotate(67.5deg);
}

#pieSliceyellowRed .pie {
  background: linear-gradient(to left, #f4c217 20%, #e34a3a 100%);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#pieSliceYellow {
  -webkit-transform: rotate(112.5deg);
  -moz-transform: rotate(112.5deg);
  -o-transform: rotate(112.5deg);
  transform: rotate(112.5deg);
}

#pieSliceYellow .pie {
  background-color: $ipu-yellow;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sk-fading-circle {
  bottom: 5.5rem !important;
  right: 8.2rem !important;
  color: $primary-color !important;

  .sk-fading-circle-1 {
    color: $primary-color !important;
  }
}

.setup-pie {
  #pieSliceYellow .pie,
  #pieSliceyellowRed .pie {
    background: $secondary-color;
  }

  #pieSliceRedBlue .pie,
  #pieSliceBlue .pie,
  #pieSliceGreenBlue .pie,
  #pieSliceGreen .pie {
    background: $primary-color;
  }

  #pieSliceRed .pie {
    background: $accent-color;
  }

  #pieSliceGreenYellow .pie {
    background: $second-accent;
  }

  .dot {
    display: none;
  }
}

.statistics {
  .v-input--text-field {
    min-width: 11rem !important;
  }

  .v-card__text {
    @media screen and (min-width: 1264px) {
      min-height: 22.5rem;
    }
  }

  canvas {
    width: 100% !important;
    height: 100%;
  }
}

.ipu-bar {
  min-width: 2rem;
  position: relative;
  margin: 0.3rem;
  border-radius: 0.3rem;
  box-shadow:
    0 2px 4px 0 rgba($black, 0.12),
    0 0 6px 0 rgba($black, 0.14);

  &#bluebar {
    background: #1478c5;
  }

  &#greenbar {
    background: $ipu-green;
  }

  &#yellowbar {
    background: $ipu-yellow;
  }

  &#redbar {
    background: $ipu-red;
  }

  .bar-value {
    position: absolute;
    display: flex;
    width: 2rem;

    strong {
      margin: auto;
    }

    &.bottom {
      bottom: -1.5rem;
    }

    &.top {
      top: -1.5rem;
    }
  }
}

.ipu-bar-search {
  min-width: 1.3rem;
  position: relative;
  margin: 0.3rem;
  border-radius: 0.3rem;
  box-shadow:
    0 2px 4px 0 rgba($black, 0.12),
    0 0 6px 0 rgba($black, 0.14);

  &#bluebar {
    background: #1478c5;
  }

  &#greenbar {
    background: $ipu-green;
  }

  &#yellowbar {
    background: $ipu-yellow;
  }

  &#redbar {
    background: $ipu-red;
  }

  .bar-value {
    position: absolute;
    display: flex;
    width: 1.3rem;

    strong {
      margin: auto;
    }

    &.bottom {
      bottom: -1.5rem;
    }

    &.top {
      top: -1.5rem;
    }
  }
}
