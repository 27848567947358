.v-time-picker-clock {
  background-color: lighten($item-background, 5%) !important;
}

.v-time-picker-clock__hand,
.v-time-picker-clock > span {
  &.accent {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
  }
}

.theme--dark.v-time-picker-clock--indeterminate .v-time-picker-clock__item--active {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}

.v-time-picker-clock--indeterminate > span.active,
.v-time-picker-clock__hand {
  background-color: $primary-color !important;
}

.v-time-picker-clock__hand::after {
  color: $primary-color !important;
}

.v-picker--date {
  margin: auto;

  @media screen and (max-width: 540px) {
    width: 100%;
  }
}

.v-picker__body {
  background: darken($item-background, 5%) !important;
}
