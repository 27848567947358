#setup-form {
  .v-input__control {
    width: 100%;
  }
}

// Fixes the size of the saved search select input field.
@media (min-width: 768px) {
  #saved-search-select {
    min-width: 180px;
  }
}
