.v-switch {
  margin-left: 0.5rem !important;

  label {
    color: $white !important;
    font-size: 12px;
    padding-left: 10px !important;
  }
}

.margin-top-switch {
  .v-input--switch {
    margin: 1rem auto 0 !important;

    .v-messages {
      min-height: 0;
    }
  }
}

.switch-bottom-right {
  bottom: -0.4rem;
  right: 0;
}

.no-margin-bottom-switch {
  .v-input--selection-controls .v-input__slot {
    margin-bottom: 0 !important;
  }

  .v-messages {
    min-height: 0 !important;
  }
}

.switch-div {
  margin-left: auto;
  min-width: 8.6rem;

  @media screen and (max-width: 500px) {
    margin-left: 0;
  }
}

.survey-radio {
  flex-direction: column;

  .v-input--selection-controls__input {
    margin: 0 !important;
  }
}

.survey-radio-group {
  .v-input--radio-group__input {
    flex-wrap: nowrap !important;
  }

  @media screen and (max-width: 600px) {
    .survey-radio {
      margin: 2px !important;
    }
  }
}
