.v-data-table {
  background-color: $item-background !important;
  word-break: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;

  thead {
    border-bottom: none !important;
  }

  .v-datatable__progress {
    height: 7px !important;

    .blue {
      background-color: $secondary-color !important;
      border-color: $secondary-color !important;
    }
  }

  tbody {
    td {
      @media screen and (max-width: 600px) {
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    tr {
      &:hover {
        background-color: lighten($item-background, 5%) !important;
      }

      &.divide {
        background: $darker-item;

        &:hover {
          background-color: $darker-item !important;
        }
      }
    }
  }

  tbody tr:not(:last-child) {
    border-bottom: 2px solid hsla(0, 0%, 100%, 0.12);
  }

  .no-data-svg {
    margin-top: 1rem;
    width: 3rem;
    height: 5rem;

    &.search-svg {
      width: 31rem;
      height: 17rem;

      @media only screen and (max-width: 600px) {
        width: 18rem;
      }
    }
  }
}

tbody {
  tr[active] {
    background-color: lighten($item-background, 7%) !important;
  }
}

.v-datatable__actions__select {
  max-width: 10rem !important;

  .v-input--select {
    margin: 0 0 0 34px !important;

    .v-input-group__selections__comma {
      padding: 0 4px 0 0;
    }
  }
}

.v-datatable__actions {
  background-color: $darker-item !important;

  .v-datatable__actions__range-controls {
    min-height: 38px;
  }

  .v-input {
    min-height: 23px;
    min-width: 50px;
  }

  .v-btn {
    margin: 0 8px;
  }

  .v-datatable__actions__select {
    .v-select {
      margin: 7px 5px;
    }
  }
}

@media screen and (max-width: 480px) {
  .v-datatable__actions__pagination {
    margin: 0 2px;
  }

  .v-datatable__actions__select {
    margin-right: auto;
    padding-left: 0;
  }

  .v-datatable__actions {
    .v-btn {
      min-width: 26px !important;
      width: 26px !important;
    }
  }
}

.v-table__overflow {
  .v-progress-linear__bar {
    min-width: 7rem;
  }
}

tr {
  position: relative;
}

.table-row-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  align-items: center;
  height: 52px !important;

  img {
    margin: 0.2rem 0.5rem 0.2rem 0;
  }
}

.wider-header-table {
  table {
    th:nth-child(1),
    td:nth-child(1),
    th:nth-child(2),
    td:nth-child(2) {
      min-width: 12rem !important;
    }
  }
}

.wider-first-header {
  table {
    th:nth-child(1),
    td:nth-child(1) {
      min-width: 9rem !important;
    }
  }
}

.timesheet-table {
  th {
    .v-checkbox {
      display: none;
    }
  }
}

.v-picker--date table {
  margin: auto;
}

.timereport-table {
  .text-xs-right {
    padding-left: 0.1rem !important;
  }

  @media screen and (max-width: 575px) {
    th,
    td {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }

    th:nth-child(1) {
      width: 5rem;
    }

    th:nth-child(2) {
      width: 2rem !important;
      min-width: 1rem !important;
    }

    th:nth-child(3) {
      width: 2rem;
      min-width: 1rem !important;
    }

    th:nth-child(4) {
      width: 7rem;
      min-width: 1rem !important;
    }
  }
}

#request-table-single {
  padding-bottom: 2rem;
}

#consult-meeting-table {
  .v-table {
    tbody {
      tr:nth-child(1),
      tr:nth-child(2),
      tr:nth-child(4n + 1),
      tr:nth-child(4n + 2) {
        background-color: darken($item-background, 8%);

        &:hover {
          background-color: darken($item-background, 5%) !important;
        }
      }

      .meeting-date,
      .meeting-type {
        &:hover {
          transform: scale(1, 1);
          background: transparent;
        }
      }
    }
  }
}

#company-user-table {
  thead {
    th:first-child {
      @media screen and (min-width: 750px) {
        padding-left: 3.2rem;
      }
    }
  }
}

.table-user-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.no-data-container {
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: auto;

  &.dashboard-table-container {
    height: 100px;

    p {
      margin-bottom: 0;
    }
  }
}

// IPU color table in search

.greenBackground,
.blueBackground,
.yellowBackground,
.redBackground,
.redYellowBackground,
.yellowGreenBackground,
.greenBlueBackground,
.blueRedBackground {
  td {
    height: 4rem !important;
  }

  .table-row-flex {
    padding-left: 16px !important;
  }
}

.redBackground {
  background: #f44336 !important;
}

.yellowBackground {
  background: #ffeb3b !important;
}

.greenBackground {
  background: #4caf50 !important;
}

.blueBackground {
  background: #008fff !important;
}

.redYellowBackground {
  background: linear-gradient(to right, #f44336 0%, #ffeb3b 100%);
}

.yellowGreenBackground {
  background: linear-gradient(to right, #ffeb3b 0%, #4caf50 100%);
}

.greenBlueBackground {
  background: linear-gradient(to right, #4caf50 0%, #008fff 100%);
}

.blueRedBackground {
  background: linear-gradient(to right, #008fff 0%, #f44336 100%);
}

.strip {
  position: absolute;
  height: inherit;
  width: 10px;
  left: 0;
  overflow: hidden;

  &.long {
    height: 100%;
  }
}

.inside-strip {
  height: 100%;
  width: 1rem;
  position: absolute;
  width: 3rem;
  left: -1rem;
}

#search-table-results {
  @media screen and (max-width: 980px) {
    th:nth-child(2),
    td:nth-child(2) {
      width: 2.8rem !important;
      min-width: 2.8rem !important;
    }
  }

  th:nth-child(3),
  td:nth-child(3) {
    height: auto !important;
    width: 2.8rem !important;
    min-width: 2.8rem !important;

    .v-small-dialog .v-menu__activator {
      min-width: 2.8rem !important;
    }
  }

  .v-table tbody td {
    white-space: normal !important;
  }

  .expanded {
    border-bottom: none !important;
  }
}

.title-container-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  h5 {
    margin-left: auto;
    margin-bottom: 0;
  }

  .v-input {
    margin-top: 0;
    max-width: 11rem;
    margin-left: auto !important;
  }

  .left-title {
    margin-left: auto;

    &.behavior-hide {
      margin-bottom: 0;
      display: flex;

      i {
        margin-left: 4rem;
        line-height: 29px;
      }

      .v-input-group__details {
        min-height: 0;
        padding-top: 0;
      }

      .v-input.v-input--selection-controls label {
        min-width: 3rem !important;
        max-width: 100% !important;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .card-title {
      margin-right: auto;
    }
  }

  &.stay-flex {
    @media screen and (max-width: 1300px) {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;

      .v-input {
        max-width: 11rem;
      }
    }
  }

  &.stats-card-top {
    .v-input {
      margin-left: 0.5rem;
    }

    @media screen and (max-width: 1300px) {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;

      .v-input {
        max-width: 11rem;
      }
    }
  }

  h5 {
    margin-left: auto;
  }
}

.add-member-table {
  th {
    text-align: left;
  }
}

.search-table {
  .ipu-wheel {
    @media screen and (max-width: 960px) {
      padding-left: 0;
    }
  }

  .types {
    h5 {
      margin-bottom: 0.3rem;
    }

    min-width: 17rem;
  }
}

.flex-table {
  display: flex;
  align-items: center;
  padding: 7px 0;

  p {
    margin-bottom: 0.2rem;
  }
}

.expanded {
  .expand-table-icon {
    transform: rotate(-180deg);
  }
}

.divider-tr {
  position: absolute;
  margin-top: 1rem;
}

.no-data {
  padding: 0.9rem 1rem;
  color: #c2ced6;
}

.no-data {
  #no-field-of-study {
    padding: 0;
  }

  .v-list__tile__title {
    overflow: visible;
    white-space: initial;
    height: auto;
    color: #fff;
    cursor: pointer;
  }
}

.table-div {
  .layout {
    margin: 0 !important;
  }
}

.disabled-row {
  background: #2c4958 !important;
}

.example-tables {
  .top-card {
    margin: 1rem 0;
  }
}
