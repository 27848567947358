.v-stepper__step__step {
  &.primary {
    background-color: $secondary-color !important;
    border-color: $secondary-color !important;
  }
}

.request-dialog {
  .v-stepper__step--active + #first-stepper {
    .v-stepper__wrapper {
      height: auto !important;
    }
  }
}

.v-stepper {
  width: 100%;
  background-color: $item-background !important;
  box-shadow: none;
}

.v-stepper__header {
  box-shadow: none;
}

.v-stepper__wrapper {
  hr {
    overflow: hidden;
    border-width: 0.4px;
    margin-top: 2rem;
    border-color: darken(#99acba, 10%);
  }
}

.request-dialog {
  .v-stepper--vertical {
    padding-bottom: 0 !important;
  }

  @media screen and (max-width: 500px) {
    .inner-dialog {
      padding: 3rem 0 1rem 0;

      .v-stepper--vertical .v-stepper__content {
        padding: 16px 50px 16px 13px;
        margin: -8px -36px -16px 22px;
      }

      .v-stepper--vertical .v-stepper__step {
        padding: 24px 10px 16px;
      }
    }
  }

  @media screen and (min-width: 750px) {
    min-width: 600px !important;
  }

  @media screen and (min-width: 800px) {
    min-width: 700px !important;
  }
}

.payment-stepper {
  .v-stepper--vertical .v-stepper__content {
    @media screen and (max-width: 600px) {
      margin-left: 0 !important;
      padding: 16px 50px 16px 13px;
    }
  }
}

.radio-group-billing {
  @media screen and (max-width: 600px) {
    padding: 48px 5px 0 !important;
  }
}
