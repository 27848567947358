.license-icon {
  width: 100%;
  height: 100%;
}

.arrow-back-sub {
  margin-bottom: 4.5px;
}

.link-back-subscription {
  &:hover {
    .arrow-back-sub {
      color: $primary-color;
    }
  }
}

.location-icon {
  font-size: 18px;
  line-height: 1.5rem;

  &.v-icon {
    color: #ffffffc9 !important;
  }
}

.icon-link {
  font-size: 1rem !important;
  margin-bottom: 5px !important;
  margin-right: 0;
}

.icon-link-public {
  font-size: 1rem !important;
  margin-bottom: 3px !important;
  margin-right: 0.2rem;
}

.un-selected-dot {
  opacity: 0.3 !important;
}

.smaller-icon {
  font-size: 1.5rem;
}

.expand-table-icon {
  margin-left: -5px;
  margin-right: 6px;
  transition: all 0.4s;
  transform: rotate(0deg);

  &.request-icon {
    margin-right: -10px;
    margin-left: 6px;
  }

  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.green-background:first-of-type {
  .star:nth-child(1) {
    background-color: $white;
  }
}

.star-container:hover {
  transform: scale(1.3, 1.3);
  background: $white;

  .star {
    background: $white;
  }
}

.dot {
  position: absolute !important;
  color: $black !important;
  z-index: 40000;
  top: 1.2rem;
  left: 6.4rem;

  &.small-dot {
    font-size: 16px;
    top: 0.3rem;
    left: 2.7rem;
  }

  &.search-dot {
    font-size: 20px;
    top: 0.6rem;
    left: 4.15rem;
  }

  &.team-dot {
    font-size: 18px;
    margin: 0 !important;
    background: $white !important;
    pointer-events: all;
    top: 2.3rem;
    left: 13.7rem;
    height: 30px !important;
    width: 30px !important;

    .v-chip__content {
      color: $black;
      height: 30px !important;
      width: 30px !important;
      top: auto !important;
      left: auto !important;
      right: auto !important;
      padding: 0 9px !important;
      cursor: pointer;

      span {
        line-height: 34px;
      }
    }

    &.v-chip {
      &.yellow-img {
        transform: rotate(-112.5deg);
      }

      &.blue-img {
        transform: rotate(67.5deg);
      }

      &.red-img {
        transform: rotate(-21.5deg);
      }

      &.red-blue-img {
        transform: rotate(22.5deg);
      }

      &.green-img {
        transform: rotate(160deg);
      }

      &.green-blue-img {
        transform: rotate(113deg);
      }

      &.green-yellow-img {
        transform: rotate(-158deg);
      }

      &.yellow-red-img {
        transform: rotate(-67.5deg);
      }
    }

    &.v-chip:focus:not(.v-chip--disabled),
    &:hover {
      &.yellow-img {
        transform: scale(1.3, 1.3) rotate(-112.5deg);
      }

      &.blue-img {
        transform: scale(1.3, 1.3) rotate(67.5deg);
      }

      &.red-img {
        transform: scale(1.3, 1.3) rotate(-21.5deg);
      }

      &.red-blue-img {
        transform: scale(1.3, 1.3) rotate(22.5deg);
      }

      &.green-img {
        transform: scale(1.3, 1.3) rotate(160deg);
      }

      &.green-blue-img {
        transform: scale(1.3, 1.3) rotate(113deg);
      }

      &.green-yellow-img {
        transform: scale(1.3, 1.3) rotate(-158deg);
      }

      &.yellow-red-img {
        transform: scale(1.3, 1.3) rotate(-67.5deg);
      }
    }
  }
}

.help-icon {
  cursor: pointer;

  &:hover {
    transform: scale(1.3, 1.3);
  }
}

.vocab-select {
  .v-icon {
    visibility: hidden;
  }
}

.skill-time-value {
  cursor: pointer;

  &:hover {
    .v-icon {
      transform: scale(1.2, 1.2);
      color: $accent-color;
    }
  }
}

.block {
  margin: 0.5rem;
  margin-left: auto;
}

.icon-circle {
  display: inline-flex;
  border-radius: 50%;
  background-color: #1565c0 !important;
}

.delete {
  float: right;
  cursor: pointer;
  transition: 0.2s all;
  margin-right: 1rem;

  &:hover {
    transform: scale(1.1, 1.1);
  }
}

.icon-size-30 {
  font-size: 30px !important;
}

.forum-member-icon {
  padding-bottom: 0.1rem;
}

.reference-icon-rating {
  font-size: 28px !important;
}

.public-profile-rating-container {
  z-index: 1;
  position: absolute;
  right: 0.5rem;
  top: -0.3rem;
}
