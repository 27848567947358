// backgrounds
$background-dark: #0a1b25;
$background-light: #354450;
$background: linear-gradient(to top, #0a1b25 0%, #354450 100%);
$item-background: #265b76;
$darker-item: #1f4a61;
//ladning
$alternative-background: #eee;
$dark-background: #232227;
$footer-darker: #111;
$border-color-primary: #e7e7e7;
$border-color-secondary: #d9d9d9;
//text
$paragraph-light: #909ca4;
$paragraph-color: #464f55;
// navbar
$navbar-primary: #172121;
$navbar-color: #0a1b25;
// graph colors
$primary-color: #00b2e8;
$secondary-color: #9c80b9;
$accent-color: #fac054;
$second-accent: #e38367;
$fourth-color: #b9f;
$error-color: #f8b5ef;

// color scheme
$success: #29a3a3;
$warning: #9c80b9;
$error: #5d6971;
$neutral: rgb(26, 61, 79);

$cta: #4caf50;
$green: #88d5a8;
$red: #c43936;
$white: #fff;
$black: #000;
//IPU
$ipu-red: #e34a3a;
$ipu-green: #1e9547;
$ipu-blue: #1262a0;
$ipu-yellow: #f4c217;

// fonts
$primary-font: "Open Sans", sans-serif;
$heading-font: "Open Sans", sans-serif;
$title-font: "Lato", sans-serif;

$body-font-family: 'Open Sans', sans-serif;
// nice gradient
//  background: linear-gradient(to right, #fac054 0%, #ff99cc 100%);

.container {
  max-width: 1200px !important;
}

.container--fluid {
  max-width: 100% !important;
}
