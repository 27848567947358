.v-alert {
  &.success {
    background-color: $success !important;
  }

  &.warning {
    background-color: $warning !important;
  }

  &.error {
    background-color: $error !important;
  }

  &.neutral {
    background-color: $neutral !important;
  }
}

.t-alert {
  font-size: 16px;
  position: fixed !important;
  top: 1rem;
  width: 80%;
  margin: 0 10%;
  z-index: 999999;
}

.default-alert {
  width: 100%;
  margin: 0;
}

.cookies-banner {
  z-index: 1000;
  position: fixed !important;
  max-width: 700px;
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
}

.alert-clipped {
  position: relative;
  top: 0;
  z-index: 0 !important;
}
