.login-denied-logo {
  width: 8rem;
}

.verification-email-sent {
  width: 12rem;
}

.onboarding-img {
  margin: 0 1rem;
  padding: 0.5rem 0;
  max-width: 100% !important;
  width: 75%;
  margin: auto;
}

.profile-content {
  position: relative;
  margin: auto;
}

.profile-content:hover #image-container {
  opacity: 1;
  background-color: rgba($black, 0.4);
}

.profile-pic-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  position: relative;

  img {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
  }

  .button-container {
    position: absolute;
    top: 0;
    left: 0;

    .v-icon {
      line-height: 2.2 !important;
    }

    @media screen and (min-width: 480px) {
      top: 0;
      left: 0;
    }
  }

  #image-container i {
    line-height: 10rem;
  }

  #image-container {
    height: 10rem;
    border-radius: 50%;
    width: 10rem;
    position: absolute;

    button {
      top: 68px;
      left: 0;
    }
  }
}

#image-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.5s ease;
  opacity: 0;
  text-align: center;
  background-color: rgba($black, 0);
}

#image-container i {
  color: $white;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s;
}

#image-container i:hover {
  color: $red;
  transform: scale(1.3, 1.3);
}

.croppa-container {
  width: 200px;
  height: 200px;
  margin: auto;
  border-radius: 50%;

  canvas {
    border-radius: 50%;
  }

  &.company-pic {
    border-radius: 0;

    canvas {
      border-radius: 0;
    }
  }

  svg {
    path {
      fill: darken($item-background, 15%);
    }
  }

  &#crop-img-event {
    display: table;
    border-radius: 0;

    canvas {
      border-radius: 0;
    }
  }
}

.medal-img {
  border-radius: 0 !important;
  height: auto !important;
  width: 2.2rem !important;

  @media screen and (min-width: 900px) {
    width: 3rem !important;
  }
}

.medal-img-medal {
  position: relative;
  border-radius: 0 !important;
  height: auto !important;
  width: 2.2rem !important;

  @media screen and (min-width: 900px) {
    width: 3rem !important;
  }
}

.disc-img-container {
  position: relative;

  h4 {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) rotate(-7deg);
    box-shadow:
      0 2px 4px 0 rgba($black, 0.12),
      0 0 6px 0 rgba($black, 0.14);
    padding: 0.5rem 1rem;
    background: rgba($darker-item, 0.8);
  }
}

.language-no-flag {
  height: 18px;
}

.profile-card-image {
  width: 1.5rem;
  display: inline-block;
  line-height: 1.5rem;
  transform: translateY(3px);
  // margin-right: .5rem;
}

.sample-disc {
  height: 150px;
  width: 100%;
}

.ipu-wheel {
  margin-bottom: 1.5rem;

  .v-tooltip {
    margin: auto;
  }
}

#number {
  position: absolute;
  bottom: -8.5rem;
  left: 0;

  @media screen and (min-width: 900px) {
    bottom: -10.7rem;
    left: -0.6rem;
  }

  .v-tooltip__content {
    min-width: 200px !important;
  }
}

.sad-guy-medium {
  width: 7rem;
}

// Design Profile
.explained-image {
  max-width: 100%;
  width: 600px;
}

.avatar-event-attendees {
  width: 45px;

  &.wider-avatar {
    width: 53px;
  }
}

.event-picture {
  height: 200px;
  width: 100%;
}

.event-image-container {
  height: 320px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

// .cropper {
//   height: 400px;
// }
