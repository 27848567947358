.animate__delay-250ms {
  animation-delay: 250ms;
}

.animate__delay-500ms {
  animation-delay: 500ms;
}

.animate__delay-750ms {
  animation-delay: 750ms;
}

.animate__delay-1250ms {
  animation-delay: 1250ms;
}

.animate__delay-1500ms {
  animation-delay: 1500ms;
}
