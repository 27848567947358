.subscription-name {
  text-transform: capitalize;

  @media screen and (max-width: 680px) {
    font-size: 1rem;
    margin-bottom: 0.3rem;
  }
}

.navbar-title {
  font-family: $title-font;

  h1 {
    font-family: $title-font !important;
  }
}

.module-description {
  max-width: 350px;
}

.discounted-price {
  color: #c2ced6;
  text-decoration: line-through;
}

.logotype {
  width: 145px;
  cursor: pointer;
  height: 25px;
}

.logotype-height {
  height: 25px;
}

.konsultkedjan-logomark {
  width: 30px;
  height: 30px;
}

.konsultkedjan-logomark-height {
  height: 30px;
}

.text-fields {
  .text-field-p {
    max-width: 20rem;
  }
}

.terms-page {
  ul {
    list-style-type: none;
  }

  .termLinks {
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.3s;

    &.activeTerms {
      color: $primary-color;
    }
  }
}

.page-header-left {
  h3 {
    margin: 0;
  }
}

.invoice-info {
  font-size: 16px;

  .contract-info {
    text-align: center;
  }
}

.attest-span {
  p {
    margin: 1rem auto 0;
  }
}

.documents {
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.auth-header {
  text-align: center;
}

.bubble {
  p {
    display: block !important;
    margin: 0.4rem 0;
  }
}

.smaller-p {
  width: 100%;
  margin-bottom: 0;
}

.names-search {
  line-height: 1.2rem !important;
  max-width: 12rem;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.titles-search {
  color: #ffffff85;
}

.show {
  max-height: 100%;
}

.t-search-amount {
  font-weight: 400;
  font-size: 1.5rem !important;
  line-height: 45px !important;
}

.t-search-amount-small {
  font-weight: 400;
  font-size: 18px !important;
  line-height: 22px !important;
}

.nameBlur {
  color: transparent;
  text-shadow: rgba(255, 255, 255, 1) 0 0 20px 0.01em;
}

.no-link-a {
  text-decoration: none !important;

  &:hover {
    color: $white !important;
  }
}

.price-text {
  line-height: 0.5;
}

.link-profile {
  text-decoration: none !important;
  color: $white !important;
}

.limited-price {
  padding: 0 0.5rem 0.5rem;

  h5 {
    margin-bottom: 0;
    color: $accent-color;
  }
}

.v-input--text-field input {
  padding-bottom: 0 !important;
}

.profile-info {
  position: relative;

  #objective {
    h4 {
      margin: 1rem 0 0;
      font-size: 1.4rem;
    }
  }

  .profile-account-info {
    h5,
    h6 {
      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .profile-information-container {
    small,
    h5,
    h6 {
      text-decoration: underline;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        color: $accent-color;
      }
    }

    #company-details {
      &:hover {
        color: $accent-color !important;
      }
    }

    #objective {
      @media screen and (max-width: 600px) {
        width: 98%;
        margin: auto;
      }
    }
  }

  .info-texts {
    @media screen and (min-width: 960px) {
      border-right: 1px solid $white;
      padding-right: 2rem;
      padding-left: 1.5rem;
    }
  }
}

.skills-hr {
  color: rgba(#b9b9b9, 0.4);
  border-color: rgba(#b9b9b9, 0.4);
}

.hr-margin {
  margin: 1rem 0;
}

.show-hr {
  transition: all 0.3s;
  margin-top: 1.5rem;
  border-color: $darker-item !important;
}

.availability-text {
  text-decoration: underline;
  cursor: pointer;
}

.availability-text,
.add-location {
  &:hover {
    color: $accent-color;
  }
}

#terms-text {
  min-height: 35rem;
}

.t-terms-textbox {
  max-height: 350px;
  overflow: auto;
}

.underline-link {
  text-decoration: underline;
}

.no-underline-link {
  text-decoration: none !important;
}

.no-underline {
  a {
    text-decoration: none !important;
  }
}

.language-title {
  font-size: 1rem;
  font-weight: bold;
}

.text-word-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.ipu-link {
  text-align: right;
  margin-top: 0.5rem;
}

.extended-assessment {
  p {
    margin-bottom: 0.4rem;
    margin-top: 1rem;
    text-decoration: underline;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      color: $primary-color !important;
    }
  }
}

.paragraph-color {
  color: #c2ced6;
}

.settings-toolbar-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.availability-chip {
  text-decoration: none !important;

  .v-chip__content {
    text-decoration: none !important;
  }
}

.capitalize {
  text-transform: capitalize;
}

.creator {
  background-color: $accent-color;
}

.creator--text {
  color: $accent-color;
}

.premium {
  background-color: $second-accent;
}

.premium--text {
  color: $second-accent;
}

.community {
  background-color: $neutral;
}

.community--text {
  color: $white;
}

.forum-select {
  max-width: 15rem;
}

.public-profile-scrollable-reviews {
  max-height: 15rem;
  overflow: auto;
}
