.ambassador-edit {
  position: absolute;
  top: 0;
  left: 0.5rem;

  @media screen and (max-width: 400px) {
    top: 8rem;
  }
}

.notification-badge {
  .v-badge {
    display: block;
  }

  .v-badge__badge {
    height: 10px !important;
    width: 10px !important;
    top: -6px !important;
    left: 20px !important;
    z-index: 1;
  }
}

.mobile-message-badge {
  .v-badge__badge {
    height: 10px !important;
    width: 10px !important;
    top: -25px !important;
    right: -10px !important;
  }
}

.message-badge {
  .v-badge__badge {
    height: 10px !important;
    width: 10px !important;
    top: -6px !important;
    right: -10px !important;
  }
}

.unread-badge {
  .v-badge__badge {
    height: 10px !important;
    width: 10px !important;
    top: 10px !important;
    right: 0 !important;
  }
}

.badge-div {
  display: inline-block;

  img {
    width: 50px;
    margin: 0 0.5rem;
    cursor: pointer;
  }
}

.invite-badge {
  width: 6rem;

  @media screen and (max-width: 630px) {
    width: 5rem;
  }
}

.reward-not-reached-badge {
  opacity: 0.1;
}

.invite-badge-container {
  position: relative;
  display: inline-block;

  @media screen and (max-width: 530px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .invite-badge {
      margin: 0.3rem;
    }
  }

  h2 {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) rotate(-7deg);
  }
}

.deep-purple.lighten-2 {
  background-color: $secondary-color !important;
  border-color: $secondary-color !important;
}

.custom-label-div {
  position: relative;

  span {
    position: absolute;
    left: 50%;
    content: "";
    height: 40px;
    height: 2px;
    background: $accent-color;
    transition: all 0.25s ease-out;
    width: 0;
    bottom: 0;
  }

  input:focus ~ span {
    width: 200px;
    width: 100%;
    margin-left: -50%;
  }
}

.community-badge {
  .v-badge__badge {
    width: 0.8rem;
    height: 0.8rem;
    position: relative;
    top: 0;
    right: 1rem;
  }
}
