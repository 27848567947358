@media screen and (max-width: 600px) {
  .v-main.mobile-padding {
    padding-bottom: 56px !important;
  }
}

.mini-wides {
  @media screen and (min-width: 1300px) {
    width: 50em !important;
  }

  @media screen and (min-width: 1200px) {
    max-width: 50em !important;
  }
}

.mw-420 {
  max-width: 420px !important;

  @media screen and (min-width: 550px) {
    width: 420px !important;
  }
}

.wides {
  padding-left: 10px !important;
  padding-top: 0 !important;
  padding-right: 10px !important;
  padding-bottom: 10px !important;

  @media screen and (min-width: 960px) {
    padding: 0 !important;
    max-width: 900px !important;
  }

  @media screen and (min-width: 1200px) {
    max-width: 1200px !important;
  }

  @media screen and (min-width: 1300px) {
    width: 1200px !important;
  }

  &.smaller-wides {
    @media screen and (min-width: 1200px) {
      max-width: 1100px !important;
    }
  }
}

.md10-wide {
  @media screen and (min-width: 1300px) {
    max-width: 1200px !important;
  }
}

.stats-wides {
  .greet {
    width: 100% !important;
    padding: 2.5rem 1rem;
  }

  @media (min-width: 960px) {
    &.flex.md10 {
      max-width: 83.33333333333334% !important;
    }
  }
}

.no-padding-container {
  padding: 0 !important;

  @media screen and (min-width: 1264px) {
    .top-card {
      margin-left: 0 !important;

      &.no-margin-right {
        margin-left: 1rem !important;
        margin-right: 0 !important;
      }
    }

    .top-card-admin {
      margin: 1rem !important;
    }
  }
}

.module-layout {
  min-height: 105px;
}

.disabled-products-layout {
  opacity: 0.1;
  pointer-events: none;
}

.sub-module-layout {
  height: 6.5rem;
}

.t-skill-container {
  height: 100px;

  @media screen and (max-width: 600px) {
    height: auto;
  }
}

.t-disc-container {
  height: 121.35px;

  @media screen and (max-width: 600px) {
    height: auto;
  }
}

// if env banner exist
.nudged {
  .v-toolbar {
    top: 40px;
  }

  .v-navigation-drawer {
    margin-top: 105px !important;
  }

  top: 40px;
}

.nudged + .v-toolbar {
  top: 40px;
}

.nudgedContent ~ .v-main {
  padding-top: 104px !important;
}

.nudgedContent {
  .v-navigation-drawer {
    margin-top: 105px !important;

    @media screen and (max-width: 960px) {
      margin-top: 40px !important;
    }
  }
}

.nudged ~ .v-main {
  padding-top: 104px !important;
}

.nudged + .application {
  .v-main {
    padding-top: 104px !important;
  }
}
// end if env banner exist

.ipu-layout {
  justify-content: center;
  align-items: flex-end;
}

.onboarding-div {
  padding: 1rem 0 3rem;

  @media screen and (min-width: 2400px) {
    display: block;

    .wides {
      margin: auto;
    }
  }
}

.timeline-container {
  padding: 1rem;

  .timeline {
    position: relative;

    &:hover .bar span {
      opacity: 1;
    }

    img {
      width: 2.5rem;
      z-index: 2;
      position: relative;
      height: 2.5rem;
      border-radius: 50%;
    }

    .bar {
      position: absolute;
      top: 0;
      width: 20rem;
      height: 2.5rem;
      background-color: rgba($primary-color, 0.5);
      border-radius: 2.5rem;
      z-index: 1;

      span {
        transition: all 0.3s;
        line-height: 2.5rem;
        margin-left: 3rem;
        opacity: 0;
      }
    }
  }

  .emp-cons-hr {
    margin: 0.5rem 0 0.8rem;
    border-color: lighten($item-background, 2%);
  }
}

.team-divs {
  .v-card__text {
    min-height: 140px;
  }

  .v-chip {
    margin: 0.2rem 0.1rem;

    .v-chip__content {
      cursor: pointer;
    }
  }

  a {
    text-decoration: none;
  }

  padding-right: 0.5rem;

  &:last-of-type {
    padding-right: 0;
  }

  @media screen and (max-width: 1264px) {
    &:nth-child(2) {
      padding-right: 0;
    }
  }

  @media screen and (max-width: 600px) {
    &:first-child {
      padding-right: 0;
    }
  }

  .v-avatar {
    width: 46px !important;
    height: 46px !important;

    img {
      padding: 4px;
    }
  }

  .v-list__tile {
    height: auto !important;
    padding: 12px 16px !important;
  }

  .v-card__title--primary {
    padding: 0.15rem 1rem !important;
  }
}

.driving-container {
  margin-bottom: 0;
  align-items: center;

  .v-chip {
    min-width: 10rem;
    max-height: 36px;
  }

  display: flex;
  flex-direction: column;
}

.disc-container {
  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: 1150px) {
    flex-direction: row;
  }

  .wheel-container {
    margin-bottom: 1rem;

    @media screen and (min-width: 1150px) {
      margin: 0 0 0 1rem;
    }
  }
}

.driving-forces-div {
  position: relative;
  display: flex;
  margin-top: 0.5rem;

  .v-btn {
    top: -0.4rem;

    i {
      font-size: 25px !important;
    }
  }
}

.behavioral-profile {
  align-items: center;

  @media screen and (min-width: 600px) {
    &.flex-column {
      margin-top: 1rem;
    }
  }
}

#profile {
  .flex-column {
    margin-top: 1rem;
  }
}

.wrapper-div {
  width: 100%;
}

#resume-carousel {
  .v-carousel__prev,
  .v-carousel__next {
    button {
      color: #000;
      background: #fff;
      border-radius: 50%;
    }
  }
}

.public-prof {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
}

#profile {
  .flex.order-xs2 {
    @media screen and (max-width: 600px) {
      margin-top: 1.5rem;
    }
  }

  p,
  span {
    word-break: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    white-space: unset !important;
  }

  .v-progress-circular__info {
    word-break: keep-all;
  }

  transition: all 0.3s;

  .pieContainer {
    opacity: 1;
    width: 180px;
    margin: 0.5rem auto 0;
  }

  .awards,
  .skills {
    margin-right: 0.7rem;
  }

  .year {
    flex-basis: 35%;
    margin-right: 0.7rem;

    @media screen and (max-width: 500px) {
      flex-basis: 20%;
    }

    &.languages {
      flex-basis: 20%;
    }
  }

  @media screen and (max-width: 500px) {
    top: 0 !important;
  }

  .table-div {
    p {
      padding: 0 0.5rem;
      font-size: 16px;
      line-height: 1.2rem;

      &.obj {
        margin: 0 0 0.7rem 0;
      }
    }

    .delete,
    .edit {
      float: none;
      margin: 0;
      cursor: pointer;
      transition: 0.2s all;

      &:hover {
        transform: scale(1.2, 1.2);
      }
    }

    .star-hover:hover {
      transform: scale(1.2, 1.2);
    }
  }

  .page-container {
    margin-top: 2rem !important;
  }

  .types {
    h5 {
      display: inline-block;
    }
  }
}

.skill-boxes {
  position: relative;
  max-width: 100%;

  .v-card__actions {
    background-color: $darker-item;
    text-align: left;

    p {
      display: flex;
      align-items: center;
    }
  }

  h5 {
    display: inline-block;
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    .d-flex {
      display: block !important;
      width: 100%;
    }
  }
}

.flex-box {
  display: flex;
  justify-content: center;

  &:hover {
    .icon-link {
      color: $accent-color !important;
    }

    h5,
    span {
      color: $accent-color !important;
    }
  }
}

.top-info {
  width: 100%;
  position: relative;
  background:
    linear-gradient(
      to right,
      rgba($primary-color, 0.8) 0%,
      rgba($secondary-color, 0.8) 100%
    );

  .top-strip {
    color: $white;
    display: flex;

    h3,
    h4 {
      margin: 0.5rem 0 0.5rem 2rem;
      display: inline-block;

      @media screen and (max-width: 840px) {
        font-size: 1.2rem;
        margin: 0.5rem;
      }

      @media screen and (max-width: 540px) {
        display: none;
      }

      @media screen and (min-width: 1264px) {
        margin: 0.5rem 0;
      }
    }

    h3 {
      margin: 0.7rem;
      display: flex;
      align-items: center;

      i {
        margin-right: 0.5rem;
      }
    }

    .v-small-dialog {
      max-width: 3rem;
    }
  }

  .tooltip-container {
    width: 8.4rem;
    margin-right: 1rem;

    .v-input__slot {
      margin-bottom: 0 !important;
    }

    .v-input {
      margin: 0;
    }

    .v-messages {
      min-height: 0 !important;
    }

    @media screen and (min-width: 500px) {
      display: inline-block;

      .v-input-group__details {
        min-height: 0;
        padding-top: 0;
      }
    }
  }

  .ipu-div {
    @media screen and (min-width: 960px) {
      padding-left: 2rem;
    }
  }
}

.app-landing {
  .page-container {
    width: 100%;
    align-items: flex-start;
  }

  .welcome-div {
    h4 {
      display: inline-block;
      width: auto;
    }

    @media screen and (min-width: 2400px) {
      width: 1200px !important;
      margin: auto;
    }
  }
}

.greet {
  width: 100%;
  padding: 2.5rem 0 0 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;

  h3 {
    font-size: 20px;
    margin: 0.7rem 0 0;
  }

  &.default-greet {
    display: initial;

    h3,
    h4 {
      display: block;
      width: 100%;

      @media screen and (max-width: 500px) {
      }
    }

    small {
      font-size: 13px;
      font-weight: 100;
    }
  }

  &.greet-buttons {
    &.sett {
      padding-top: 0 !important;

      @media screen and (max-width: 1160px) {
        padding: 0;
      }
    }
  }
}

.table-div {
  transition: 1s all;
  word-break: break-word;
}

.multiple-editable {
  transition: 1s all;
  overflow: hidden;
}

// meetings, this code is old and will most likely be deprecated soon
.meeting-date,
.meeting-type {
  position: relative;
  cursor: pointer;
  margin: 1rem 0.5rem 1rem 0.2rem;
  display: inline-block;
  border: 2px solid $primary-color;
  border-radius: 0.2rem;
  text-align: center;
  transition: all 0.3s;
  height: 8rem;
  width: 9rem;

  &:hover {
    transform: scale(1.03, 1.03);
    background: darken($item-background, 10%);
    box-shadow:
      0 2px 4px 0 rgba($black, 0.12),
      0 0 6px 0 rgba($black, 0.04);
  }

  .top-strip {
    background: $primary-color;
    color: $white;
    font-weight: bold;
    font-size: 16px;
    height: 1.6rem;

    p {
      line-height: 1.6rem;
      margin: 0;
    }
  }

  .meeting-body {
    padding: 1rem;

    &.type {
      padding: 1.5rem 1.5rem;
    }

    p {
      margin: 0;
      line-height: 26px;
    }

    b {
      font-size: 22px;
    }

    i {
      font-size: 22px;
    }

    .phone {
      width: 16px;
      pointer-events: none;
    }

    .webcam {
      width: 25px;
      pointer-events: none;
    }

    .person {
      width: 18px;
      pointer-events: none;
    }
  }

  &.request-meeting-date {
    height: 7rem;
    width: 8rem;

    .meeting-body {
      padding: 0.7rem;

      p {
        margin: 0;
        line-height: 20px;
      }

      b {
        font-size: 18px;
      }

      &.type {
        padding: 1.5rem 0.7rem;
      }
    }
  }
}

.meeting-wrapper {
  .meeting-date {
    width: 7rem;
    height: 6rem;
    display: inline-flex;

    .meeting-body.type {
      margin: auto;
    }

    @media screen and (min-width: 750px) {
      width: 8rem;
      height: 7rem;

      .meeting-body.type {
        margin: auto;
      }
    }
  }
}

.selected {
  border: 2px solid $secondary-color !important;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.12),
    0 0 6px 0 rgba(0, 0, 0, 0.04);
  transform: scale(1.03, 1.03);
  background: darken($item-background, 10%);

  .top-strip {
    background: $secondary-color;
  }
}

.product-divs {
  .meeting-info {
    padding-left: 3rem;
  }
}

.meeting-date:first-of-type {
  margin-left: 1rem;
}

.meeting-type-container {
  max-width: 24rem;

  .v-menu {
    label {
      font-size: 14px;
      left: 40px;
    }
  }
}

.accept-meeting {
  p {
    text-align: center;
  }

  .input-fields {
    margin: auto;
    max-width: 20rem;
  }
}

.meeting-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  align-items: center;
  margin: 0.7rem 0;
  word-break: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;

  &.status {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  p:first-of-type {
    color: darken($white, 35%);

    @media screen and (min-width: 500px) {
      min-width: 10rem;
    }
  }

  &.invoice {
    border-bottom: 0.5px solid #888;
    padding: 1rem;
    margin: 0 auto;

    @media screen and (max-width: 500px) {
      display: block;
    }

    p,
    span {
      min-width: 16rem;
      word-wrap: break-word;
      max-width: 100%;
    }
  }

  &.chip-inv {
    padding: 0.7rem 1rem !important;
  }

  p {
    margin: 0;
    margin-right: 0.5rem;
  }

  &.message {
    align-items: flex-start;
    margin-top: 0.2rem;

    p {
      @media screen and (min-width: 500px) {
        width: 10rem;
      }
    }
  }
}

.absolute-left-margin {
  position: absolute;
  left: -0.5rem;
}
